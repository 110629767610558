<template>
  <div v-if="show" class="results">
    <div class="panel">
      <div id="results_div">
        <h2>
          Results for: <i>{{ searchResultsFor }}</i>
        </h2>
        <p>Click on a result row to see further details.</p>

        <div id="search-bar">
          <v-text-field
            outlined
            v-model="searchText"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            clearable
            hide-details
          />
        </div>

        <br />

        <div class="bootstrap">
          <b-table
            sticky-header="60vh"
            :filter="searchText"
            :no-border-collapse="true"
            :show-empty="true"
            :fields="headers"
            head-variant="light"
            sort-icon-left
            responsive="sm"
            selectable
            select-mode="single"
            hover
            @row-clicked="item => showDetails(item)"
            :items="tableMappedResults"
          >
            <template v-slot:cell(stops)="data">
              <template
                v-if="
                  data.item.stops != undefined && data.item.stops.length > 0
                "
              >
                <v-icon>mdi-stop-circle</v-icon>
              </template>
            </template>
          </b-table>
        </div>

        <div>Page: {{ page + 1 }}</div>
        <div class="bottom-buttons">
          <v-btn :disabled="page == 0" v-on:click="previousPage"
            >Previous</v-btn
          >
          <v-btn v-on:click="nextPage" :disabled="results.length < 10"
            >Next</v-btn
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import vehicleFunctions from "@/mixins/vehicleFunctions.js";
import { mapGetters } from "vuex";

export default {
  mixins: [vehicleFunctions],
  data() {
    return {
      searchText: "",
      show: false,
      headers: [
        { label: "Stop", key: "stops", sortable: true },
        { label: "VIN", key: "vehicle.vin" },
        { label: "Title", key: "title.currentTitleNo" },
        { label: "Plate", key: "plate.plate" },
        { label: "Class", key: "plate.plateClass", sortable: true },
        { label: "Issue Year", key: "plate.issueYear", sortable: true },
        { label: "Owner", key: "name", sortable: true },
        { label: "Make", key: "vehicle.make", sortable: true },
        { label: "Model", key: "vehicle.model", sortable: true },
        { label: "Year", key: "vehicle.year", sortable: true }
      ]
    };
  },
  computed: {
    ...mapGetters({
      results: "resultsArray",
      page: "page",
      isAdvancedSearch: "isAdvancedSearch"
    }),
    tableMappedResults() {
      const tableResults = [];
      for (let i = 0; i < this.results.length; i++) {
        let temp = Object.assign({}, this.results[i]);
        let name = "";
        if (
          this.results[i].lessors != undefined &&
          this.results[i].lessors.length > 0
        ) {
          const lessors = this.sortLess(this.results[i].lessors);
          for (let x = 0; x < lessors.length; x++) {
            name += lessors[x].name;
            if (lessors.length > 0 && x < 1) {
              if (lessors[x].conjCode == 1) {
                name += " And ";
              } else if (lessors[x].conjCode == 2) {
                name += " Or ";
              }
            }
          }
        } else if (this.results[i].owners != undefined) {
          const owners = this.results[i].owners;
          for (let x = 0; x < owners.length; x++) {
            name += owners[x].name || owners[x].customerName;
            if (owners.length > 0 && x < 1) {
              if (owners[x].conjCode == 1) {
                name += " And ";
              } else if (owners[x].conjCode == 2) {
                name += " Or ";
              }
            }
          }
        }
        temp = Object.assign(temp, { name: name });

        // Modify data for archive records
        if (this._.has(temp.vehicle, "stops")) {
          temp.stops = temp.vehicle.stops;
        }
        if (this._.has(temp.title, "titleNo")) {
          temp.title.currentTitleNo = temp.title.titleNo;
        }
        if (this._.has(temp.vehicle, "makeCode")) {
          temp.vehicle.make = temp.vehicle.makeCode;
          temp.vehicle.model = temp.vehicle.modelCode;
          temp.vehicle.year = temp.vehicle.modelYear;
        }
        if (this._.has(temp, "registration")) {
          temp.plate = {
            plate: temp.registration.currentPlate.plateNo,
            plateClass: temp.registration.currentPlate.class,
            issueYear: temp.registration.currentPlate.issueYear
          };
        }
        tableResults.push(temp);
      }
      return tableResults;
    },
    searchResultsFor() {
      if (!this.isAdvancedSearch) {
        return this.$store.getters.simpleSearch;
      }

      const store = this.$store.getters;
      const advanced = {
        "Title Number": store.titleNo,
        VIN: store.vin,
        "VIN Last 8": store.vinLast8,
        "Plate Number": store.plateNo,
        Class: store.plateClass,
        "Issue Year": store.PlateClassIssueYear,
        Make: store.makeCode,
        Model: store.modelCode,
        Name: store.name,
        "Model Year": store.modelYear,
        "Street AND Number": store.streetAddress,
        City: store.city,
        State: store.state,
        Zip: store.zip
      };

      const resultsStringArray = [];
      for (const label in advanced) {
        const searchItem = advanced[label];

        if (![undefined, null, ""].includes(searchItem)) {
          resultsStringArray.push(label + ": " + searchItem);
        }
      }
      const resultsForString = resultsStringArray.join(", ");

      return resultsForString;
    }
  },
  methods: {
    backToSearch() {
      this.$root.$emit("setLoading", true);
      this.$router.push({ name: "Home" });
    },
    showDetails(item) {
      this.$store.commit("selectedIndex", this.results.indexOf(item));

      if (item.title != undefined) {
        this.$store.commit("titleCodeID", item.title.statusID);
      }

      if (item.vehicle.vin) {
        const sendURL = "/star/inquiry/search?q=" + encodeURI(item.vehicle.vin);
        const sendMethod = "GET";
        const sendData = JSON.stringify({
          vin: item.vehicle.vin
        });
        this.$root.$emit("setLoading", true);
        this.$api.simpleSearch(sendURL, sendMethod, sendData).then(result => {
          item = result[0];
          if (item.pendingTransaction !== undefined) {
            this.$store.commit("resultsArray", result);
          }

          this.$root.$emit("setLoading", false);
          this.$router.push({
            name: "Details",
            params: {
              vin: item.vehicle.vin,
              make: item.vehicle.make,
              year: item.vehicle.year
            }
          });
        });
      }
    },
    async nextPage() {
      const page = this.page + 1;
      this.$store.commit("page", page);

      this.$root.$emit("setLoading", true);

      const streetAddress =
        this.$store.getters.streetNumber || this.$store.getters.streetAddress
          ? this.$store.getters.streetNumber +
            " " +
            this.$store.getters.streetAddress
          : null;

      const data = {
        titleNo: this.$store.getters.titleNo,
        vin: this.$store.getters.vin,
        vinLast8: this.$store.getters.vinLast8,
        plateNo: this.$store.getters.plateNo,
        plateClass: this.$store.getters.plateClass,
        PlateClassIssueYear: this.$store.getters.plateClassIssueYear,
        makeCode: this.$store.getters.makeCode,
        modelCode: this.$store.getters.modelCode,
        name: this.$store.getters.name,
        modelYear: this.$store.getters.modelYear,
        streetAddress,
        city: this.$store.getters.city,
        state: this.$store.getters.state,
        zip: this.$store.getters.zip,
        page: this.$store.getters.page,
        partialPlateNo: "",
        disabledPlacard: "",
        bodyType: "",
        titanBodyCode: "",
        majorColorCode: "",
        minorColorCode: ""
      };

      const results = await this.$api.inquirySearch(data, {
        404: error => {
          const page = this.page - 1;
          this.$root.$emit("setLoading", false);
          this.$store.commit("page", page);
          this.$store.dispatch("setGlobalAlertState", {
            title: "Invalid",
            description: "There are no more results.",
            icon: "error"
          });
          throw error;
        }
      });

      if (results == "No results found") {
        this.$store.dispatch("setGlobalAlertState", {
          title: "Invalid",
          description: "No results found",
          icon: "error"
        });
        const page = this.page - 1;
        this.$store.commit("page", page);
        this.$root.$emit("setLoading", false);
        return false;
      }
      this.$store.commit("resultsArray", results);
      this.$root.$emit("setLoading", false);
    },
    async previousPage() {
      if (this.$store.getters.page == 0) {
        return false;
      }
      const page = this.page - 1;
      this.$store.commit("page", page);

      const streetAddress =
        this.$store.getters.streetNumber || this.$store.getters.streetAddress
          ? this.$store.getters.streetNumber +
            " " +
            this.$store.getters.streetAddress
          : null;

      const data = {
        titleNo: this.$store.getters.titleNo,
        vin: this.$store.getters.vin,
        vinLast8: this.$store.getters.vinLast8,
        plateNo: this.$store.getters.plateNo,
        plateClass: this.$store.getters.plateClass,
        PlateClassIssueYear: this.$store.getters.plateClassIssueYear,
        makeCode: this.$store.getters.makeCode,
        modelCode: this.$store.getters.modelCode,
        name: this.$store.getters.name,
        modelYear: this.$store.getters.modelYear,
        streetAddress,
        city: this.$store.getters.city,
        state: this.$store.getters.state,
        zip: this.$store.getters.zip,
        page: this.$store.getters.page,
        partialPlateNo: "",
        disabledPlacard: "",
        bodyType: "",
        titanBodyCode: "",
        majorColorCode: "",
        minorColorCode: ""
      };

      this.$root.$emit("setLoading", true);

      const results = await this.$api.inquirySearch(data, {
        404: error => {
          this.$root.$emit("setLoading", false);
          this.$store.dispatch("setGlobalAlertState", {
            title: "Error",
            description: "There was an issue with the server",
            icon: "error",
            actions: [
              {
                text: "OK",
                handler: () => {
                  this.$store.dispatch("hideGlobalAlert");
                  this.$store.commit("page", this.page++);
                },
                color: "primary"
              }
            ]
          });
          throw error;
        }
      });

      if (results == "No results found") {
        this.$store.dispatch("setGlobalAlertState", {
          title: "Invalid",
          description:
            "No results found! Something has changed in the data. Please try your search again.",
          icon: "error"
        });
        this.$root.$emit("setLoading", false);
        this.backToSearch();
        return false;
      }
      this.$store.commit("resultsArray", results);
      this.$root.$emit("setLoading", false);
    },
    sortLess: function (less) {
      var sorted = less.slice(0);
      sorted.sort(function (a, b) {
        var x = a.sequenceNo;
        var y = b.sequenceNo;
        return x < y ? -1 : x > y ? 1 : 0;
      });
      return sorted;
    }
  },

  mounted() {
    this.$store.commit("expRedir", false);

    if (
      this.results == undefined ||
      this.results.length < 1 ||
      this.results.length == undefined
    ) {
      this.$router.push({ name: "Home" });
    }

    if (this.results.length == 1) {
      this.showDetails(this.results[0]);
      return;
    }
    this.show = true;

    this.$root.$emit("setLoading", false);
  }
};
</script>
<style scoped>
#results_div .v-data-table table {
  max-height: 80vh;
}

#results_div #search-bar {
  min-width: 200px;
  width: 25%;
}

.bottom-buttons {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.bottom-buttons button {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
</style>
